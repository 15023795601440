// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

/* Custom CSS */

label {
    cursor: pointer;
}

.text-right {
    text-align: right;
}

/* Hack to fix KYC table CSS */

#kyc-table .form-check input {
    margin-top: 7px;
}

#kyc-table td:last-child {
    text-align: right;
}

@media only screen and (max-width: 767px) {

    /* Disable field zoom for iOS */
    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select:focus,
    select,
    textarea {
        font-size: 1rem !important;
    }

    input[type="datetime"],
    input[type="datetime-local"] {
        text-align: left !important;
    }

    /* Margin */

    .mobile-mt-2 {
        margin-top: 0.5rem !important;
    }

    .mobile-mb-2 {
        margin-bottom: 0.5rem !important;
    }

    /* Footer */
    footer .col-5 {
        width: 100%;
    }

    footer .col {
        flex: 0 0 100%;
    }

    footer .text-right {
        text-align: left;
    }
}
